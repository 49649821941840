import {
  buildDateSortCriterion,
  buildRelevanceSortCriterion,
  SortCriterion,
  SortOrder,
  SearchEngine,
  loadContextActions,
  buildSearchEngine,
  Result,
} from '@coveo/headless';
import { supportFetch } from '../../middleware/supportFetch';
import { v4 as uuidv4 } from 'uuid';

export enum PIPELINES {
  CUSTOMER = 'Customer',
  DEFLECTION = 'Deflection',
}

export enum HUBS {
  ARTICLE_PAGE = 'article_page',
  HELP_PAGE = 'help_page',
  FIND_PAGE = 'find_page',
  CREATE_PAGE = 'create_page',
}


export const getToken = () => {
  return new Promise<string>((resolve, reject) => {
    supportFetch
      .post(`/public/v1.0/coveo/token/generate`, {
        type: 'User'
      })
      .then(async (res: any) => {
        resolve(res.token);
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
};

export const sortCriteria: [string, SortCriterion][] = [
  ['Relevance', buildRelevanceSortCriterion()],
  ['Date (Ascending)', buildDateSortCriterion(SortOrder.Ascending)],
  ['Date (Descending)', buildDateSortCriterion(SortOrder.Descending)],
];

export enum SORT_CRITERIA {
  RELEVANCE = 0,
  DATE_ASCENDING = 1,
  DATE_DESCENDING = 2,
}

export const fieldsToInclude = [
  'syslanguage',
  'sftagsc',
  'sfsummary',
  'docoutline',
  'systitle',
  'sfkatotalviewcount',
  'sysurihash',
  'sfistemplatec',
  'sfkafirstpublisheddate',
  'urihash',
  'parents',
  'sfkalastmodifieddate',
  'sfid',
  'sysuri',
  'sfdcexpandedconcept',
  'sysprintableuri',
  'sfkalastmodifiedbyname',
  'sflastpublisheddate',
  'systransactionid',
  'documentsource',
  'sfkaarticlenumber',
  'sfcontributornamec',
  'sfurlname',
  'sfsystemmodstamp',
  'validityscore',
  'sfkacreatedbyname',
  'sfisvisibleinapp',
  'sysconcepts',
  'concepts',
  'printableuri',
  'documenttype',
  'sfkacreatedbyid',
  'sysindexeddate',
  'sfknowledgearticleid',
  'sfarticlecreateddate',
  'sfcreatedbyid',
  'permanentid',
  'sfkaisdeleted',
  'transactionid',
  'sfisvisibleinprm',
  'title',
  'jikey',
  'objecttypelabel',
  'sfversionnumber',
  'sfarticlenumber',
  'date',
  'objecttype',
  'sfkacreateddate',
  'foldingcollection',
  'sfarticletotalviewcount',
  'sfkacaseassociationcount',
  'case',
  'sflastmodifiedbyid',
  'sfcontentc',
  'sfkavid',
  'syssfid',
  'syssfownerid',
  'sourcetype',
  'product',
  'sysconnectortype',
  'sftitle',
  'rowid',
  'sforganizationid',
  'syssfcreatedbyid',
  'sfkalastmodifiedbyid',
  'sfarticlecaseattachcount',
  'size',
  'sfislatestversion',
  'sysdocumenttype',
  'sfcreateddate',
  'sfkalastpublisheddate',
  'clickableuri',
  'sfarticlecreatedbyid',
  'syssource',
  'sfownerid',
  'orderingid',
  'sfpublishstatus',
  'syssize',
  'sysdate',
  'sflanguage',
  'sysparents',
  'sfcurrencyisocode',
  'sfkbid',
  'sfarticlecreatedbyname',
  'foldingchild',
  'sfdcexpandedproduct',
  'objecttypelabelplural',
  'syssfcreateddate',
  'sflastmodifieddate',
  'sfisvisibleinpkb',
  'source',
  'sfisdeleted',
  'sfkasystemmodstamp',
  'sfisvisibleincsp',
  'sfvalidationstatus',
  'sflastmodifiedbyname',
  'collection',
  'sfdcproduct',
  'sfkaid',
  'syssourcetype',
  'sfarticletype',
  'indexeddate',
  'connectortype',
  'filetype',
  'sfdcconcept',
  'sfcreatedbyname',
  'sysclickableuri',
  'sysfiletype',
  'language',
  'sffirstpublisheddate',
  'sfownername',
  'foldingparent',
  'sysrowid',
  'uri',
  'syscollection',
  'ytthumbnailurl',
  'ytdescription',
];

export const dispatchContextValues = (session: any, engine: SearchEngine) => {
  // Check if impersonation or test account is used, enabled and set coveo engine context on engine
  // Load Custom Engine Context Actions
  engine.dispatch(
    loadContextActions(engine).addContext({
      contextKey: 'isImpersonated',
      contextValue:
        session.impersonatedBy && session.impersonatedBy.name
          ? 'true'
          : 'false',
    })
  );

  // Dispatch Context Action for Test Account user
  engine.dispatch(
    loadContextActions(engine).addContext({
      contextKey: 'isTestAccount',
      contextValue:
        session.accounts &&
          session.accounts[0] != null &&
          session.accounts[0].Name &&
          session.accounts[0].Name === 'Test Prospect Americas'
          ? 'true'
          : 'false',
    })
  );

  // Add the Account ID to context
  engine.dispatch(
    loadContextActions(engine).addContext({
      contextKey: 'accountid',
      contextValue: session.accounts[0].Id,
    })
  );

  // Add the Account Name to context
  engine.dispatch(
    loadContextActions(engine).addContext({
      contextKey: 'accountname',
      contextValue: session.accounts[0].Name,
    })
  );
 
  // Add the User Id
  engine.dispatch(
    loadContextActions(engine).addContext({
      contextKey: 'userid',
      contextValue: session.id,
    })
  );
  
  // Create the Context for Coveo for the Session isDeflectionGenAI flag
  engine.dispatch(
    loadContextActions(engine).addContext({
      contextKey: 'isDeflectionGenAI',
      contextValue: true,
        // Check if isDeflectionGenAI is undefined or null, if so set to false, otherwise set to the value
        // session.flags && session.flags.isDeflectionGenAI
        //   ? 'true'
        //   : 'false',
    })
  ); 
};

// const fallbackVisitorId: string = uuidv4();

export const getCoveoVisitorId = (sessionVisitorId: string) => {
  // Use the Session Visitor ID that we generate using the Username string from session
  let visitorId = sessionVisitorId;
  // Set the local storage visitor ID to the username specific uuid
  localStorage.setItem('visitorId', visitorId);

  return visitorId;
};
  
export const buildCoveoSearchEngine = (token: string, visitorId: string) => {

  //let logObj: any = {};

  const engine = buildSearchEngine({
    configuration: {
      organizationId: 'mongodb',
      platformUrl: process.env.COVEO_ENDPOINT, // "https://support.mongodb.com/search_platform",
      accessToken: token,
      preprocessRequest: (request: any, clientOrigin) => {
        if (clientOrigin === 'searchApiFetch') {
          // debugger;
          const body = JSON.parse(request.body);
          // Lets force the visitor ID to the requests
          body.visitorId = visitorId;
          body.analytics.clientId = visitorId;
          request.body = JSON.stringify(body);
        }

        if (clientOrigin === 'analyticsFetch') {
          // debugger;
          const body = JSON.parse(request.body);
          // Lets force the visitor ID to the requests
          body.clientId = visitorId;
          request.body = JSON.stringify(body);
          // Lets also modify the visitor url parameter for analytics
          const url = new URL(request.url);
          url.searchParams.set('visitor', visitorId);

          //cruel world 
          if(process.env.COVEO_ENDPOINT) {
            const uglify = url.toString().replace('analytics.cloud.coveo.com','support.mongodb.com/search_metrics/')
            const uglify2 = uglify.replace('/v15/analytics/','/v15/a/')
            request.url = uglify2;
          }
        }
        return request;
      },
      analytics: { 
        analyticsClientMiddleware: (eventName: string, payload: any) => { 

          /** Add additional logging to coveo and/or console */
          /** Lets wrap a try-catch here, just on a case, don't want to cause a crash */
          try { 

            if (eventName === 'search' || eventName === 'click') {

              // Search string
              // if (engine?.state?.query?.q) {
              //   logObj.search = engine?.state?.query?.q;
              // }

              // // Facet selected
              // if (payload.facetState?.length > 0) {
              //   logObj.facets = payload.facetState;
              // }

              // Pipeline
              if (engine.state.pipeline) {
                //logObj.pipeline = engine.state.pipeline;
                payload.customData['context_pipeline'] = engine.state.pipeline;
              }

              // Number of records
              if (engine.state.search.response?.totalCountFiltered) {
                //logObj.totalresults = engine.state.search.response.totalCountFiltered;
                payload.customData['context_totalresults'] = engine.state.search.response.totalCountFiltered;
              }
            }

            if (eventName === 'click') {

              // Article number
              // if (payload.customData.sfarticlenumber) {
              //   logObj.articlenumber = payload.customData.sfarticlenumber;
              // }

              // // Coveo URI
              // if (payload.documentUri) {
              //   logObj.coveouri = payload.documentUri;
              // }

              // Title
              if (payload.documentTitle) {
                //logObj.title = payload.documentTitle;
                payload.customData['context_articletitle'] = payload.documentTitle;  
              }

              // Source
              //logObj.source = payload.sourceName;

              // Click rank
              // if (clickRank) {
              //   logObj.clickRank = clickRank;
              //   // this is Click Rank is automatically added to context in Coveo
              // }
            }

            /** Add to all pages if available */

            // Is Impersonated
            // if (payload.customData.context_isImpersonated) {
            //   logObj.isImpersonated = payload.customData.context_isImpersonated;
            // }

            // Is Test Account
            // if (payload.customData.context_isTestAccount) {
            //   logObj.isTestAccount = payload.customData.context_isTestAccount;
            // }

            // // Account Id
            // if (payload.customData.context_accountid) {
            //   logObj.accountId = payload.customData.context_accountid;
            // }

            // // Account Name
            // if (payload.customData.context_accountname) {
            //   logObj.accountName = payload.customData.context_accountname;
            // }

            // // User Id
            // // if (payload.customData.context_userid) {
            // //   logObj.userId = payload.customData.context_userid;
            // // }

            // // Event Type
            // if (payload.eventType) {
            //   logObj.eventType = payload.eventType;
            // }

            // // Event Value
            // if (payload.eventValue) {
            //   logObj.eventValue = payload.eventValue;
            // }

            // // Origin Level 1
            // if (payload.originLevel1) {
            //   logObj.originLevel1 = payload.originLevel1;
            // }

            // if (payload.clientId) {
            //   logObj.clientId = payload.clientId;
            // }

            // User Agent
            // if (payload.userAgent) {
            //   logObj.userAgent = payload.userAgent;
            // } 
            
            //console.log(logObj);

             

          } catch (err) {

            console.error({
              type: 'analytics',
              method: 'analyticsClientMiddleware',
              error: err
            });
          }

          return payload;
        }
      },
    },
  });

  return engine;

}; 
 
